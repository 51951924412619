import React, { useEffect } from "react";
import CheckboxGroup from "common/components/Form/CheckboxGroup";
import DatePickerInput from "common/components/Form/DatePickerInput";
import RadioGroup from "common/components/Form/RadioGroup";
import useI18nTranslate from "common/hooks/useI18nTranslate";
import { useFormState, useForm, Field } from "react-final-form";
import { FieldContainer, FieldPadding } from "../Users/CreateUser.styles";
import { SinglePatientExportValues } from "./SinglePatientExport";
import { FieldInstructions } from "./SinglePatientExport.styles";
import { formatDate } from "common/utils/general";
import dayjs from "dayjs";
import { FormField } from "common/components/Form";
import { FormattedMessage } from "react-intl";

const defaultReportSectionsCSV = {
  calendar_entries: false,
  conversations: false,
  documents: false,
  input_forms: false,
  observations: false,
  user_information: false,
  programs: false,
};

const defaultReportSectionsPDF = {
  values: false,
  forms: false,
  messages: false,
  symptoms: false,
};

interface ReportSectionSettings {
  calendar_entries?: boolean;
  conversations?: boolean;
  ccda_documents?: boolean;
  mosaiq_documents?: boolean;
  input_forms?: boolean;
  observations?: boolean;
  user_information?: boolean;
  programs?: boolean;
  values?: boolean;
  forms?: boolean;
  messages?: boolean;
  symptoms?: boolean;
}

type SectionType = keyof ReportSectionSettings;

interface FileTypeSettings {
  pdf: boolean;
  csv: boolean;
}

type FileType = keyof FileTypeSettings;

const defaultFileTypeSettings = {
  pdf: false,
  csv: true,
};

const fileKeys = Object.keys(defaultFileTypeSettings) as FileType[];

// eslint-disable-next-line max-lines-per-function
const SinglePatientExportFields = () => {
  const csvKeys = Object.keys(defaultReportSectionsCSV) as SectionType[];
  const pdfKeys = Object.keys(defaultReportSectionsPDF) as SectionType[];
  const t = useI18nTranslate();
  const { values: formValues }: { values: SinglePatientExportValues } =
    useFormState();

  const { change } = useForm();

  // Clear the dataSelection fields when the fileType changes
  useEffect(() => {
    change("dataSelection", []);
  }, [formValues.fileType]);

  const sectionOptions = formValues.fileType === "csv" ? csvKeys : pdfKeys;

  return (
    <div data-testid="CreateUserFields">
      <FieldContainer data-testid="file-type-container">
        <FormField
          label={"csv_export.settings.file_type"}
          showRequiredIndicator
          required
          inactiveLabel
          labelWidth={30}
        >
          <FieldPadding>
            <RadioGroup
              name="fileType"
              options={fileKeys.map(value => ({
                label: t(`csv_export.settings.${value}`),
                value,
              }))}
            />
          </FieldPadding>
        </FormField>
        <FieldInstructions>
          {t("csv_export.settings.file_info")}
        </FieldInstructions>
      </FieldContainer>

      <FieldContainer data-testid="data-selection-container">
        <FormField
          label={"csv_export.settings.data_selection"}
          showRequiredIndicator
          required
          inactiveLabel
          labelWidth={30}
        >
          <FieldPadding>
            <CheckboxGroup
              name="dataSelection"
              options={sectionOptions.map(value => ({
                label: t(`csv_export.settings.${value}`),
                value,
              }))}
            />
          </FieldPadding>
        </FormField>
        <FieldInstructions>
          {t("csv_export.settings.selection_info")}
        </FieldInstructions>
      </FieldContainer>

      <FieldContainer data-testid="start_date-container">
        <Field name="startDate">
          {({ input }) => (
            <DatePickerInput
              {...input}
              defaultValue={undefined}
              label="csv_export.settings.start_date"
              labelWidth={30}
              max={formatDate(dayjs(formValues.endDate))}
              actions={[
                {
                  title: t("csv_export.settings.clear_start_date"),
                  onClick: () => change("startDate", ""),
                  type: "accent",
                },
              ]}
            />
          )}
        </Field>
      </FieldContainer>
      <FieldContainer data-testid="end_date-container">
        <Field name="endDate">
          {({ input }) => (
            <DatePickerInput
              {...input}
              defaultValue={undefined}
              label="csv_export.settings.end_date"
              labelWidth={30}
              min={formatDate(dayjs(formValues.startDate))}
              actions={[
                {
                  title: t("csv_export.settings.clear_end_date"),
                  onClick: () => change("endDate", ""),
                  type: "accent",
                },
              ]}
            />
          )}
        </Field>
        <FieldInstructions>
          <FormattedMessage id={`csv_export.settings.date_range_info`} />
        </FieldInstructions>
      </FieldContainer>
    </div>
  );
};

export default SinglePatientExportFields;
