import React, { useCallback } from "react";
import ReactSelect, {
  ActionMeta,
  components,
  InputProps,
  MultiValue,
  OnChangeValue,
  Props as ReactSelectProps,
  StylesConfig,
  Theme,
} from "react-select";
import { mobileUA } from "common/utils/layout";
import { SiteSettings } from "common/utils/holvikaari";
import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";
import { colors as dsColors } from "@netmedi/frontend-design-system/dist/styles/variables";

type SiteColor = { primary: string; primary25: string };

// Set the input to readonly for mobile devices so the keyboard doesn't pop up
const Input = <Option, IsMulti extends boolean>(
  props: InputProps<Option, IsMulti>,
) => (
  <components.Input {...props} readOnly={mobileUA} aria-haspopup="listbox" />
);

type NonNullableOnChangeValue<
  Option,
  IsMulti extends boolean,
> = IsMulti extends true ? MultiValue<Option> : Option;

type OnChange<Option, IsMulti extends boolean> = (
  value: NonNullableOnChangeValue<Option, IsMulti>,
  action: ActionMeta<Option>,
) => void;

const getOnChangeCb =
  <Option, IsMulti extends boolean>(onChange?: OnChange<Option, IsMulti>) =>
  (value: OnChangeValue<Option, IsMulti>, action: ActionMeta<Option>) => {
    if (!value) return;
    onChange?.(value as NonNullableOnChangeValue<Option, IsMulti>, action);
  };

export type SelectProps<Option, IsMulti extends boolean> = Omit<
  ReactSelectProps<Option, IsMulti>,
  "onChange"
> & { onChange?: OnChange<Option, IsMulti>; error?: boolean };

const Select = <Option, IsMulti extends boolean = false>(
  props: SelectProps<Option, IsMulti>,
) => {
  const customStyles: StylesConfig<any, any> = {
    menu: provided => ({ ...provided, zIndex: 2 }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? "0 0 0pt 2pt #f2f2fc;" : "none",
      minHeight: rem(38),
      borderColor: props.error ? "red" : "hsl(0, 0%, 80%)",
    }),
  };

  const customTheme = (theme: Theme): Theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      ...colors(),
      danger: "#cd2020", //red700
      dangerLight: "#ffeeee", //red50
    },
  });

  const colors = (): SiteColor => {
    const site: string = SiteSettings.site;
    const siteColors: Record<string, SiteColor> = {
      default: {
        primary: dsColors.blue500,
        primary25: dsColors.blue50,
      },
      chuv: {
        primary: "#17a345",
        primary25: "rgba(23, 163, 69, 0.25)",
      },
      hug: {
        primary: "#93CFA9",
        primary25: "rgba(147, 207, 169, 0.25)",
      },
      ["krankenhaus-reinbek"]: {
        primary: "#1e7a9e",
        primary25: "rgba(30, 122, 158, 0.25)",
      },
      ["strahlentherapie-schwabing"]: {
        primary: "#3fa83c",
        primary25: "rgba(63, 168, 60, 0.25)",
      },
    };

    return siteColors[site] ?? siteColors["default"];
  };

  const onChange = useCallback(getOnChangeCb(props.onChange), [props.onChange]);

  return (
    <ReactSelect
      {...props}
      onChange={onChange}
      blurInputOnSelect={mobileUA}
      components={{ ...props.components, Input }}
      styles={{
        ...props.styles,
        ...customStyles,
      }}
      theme={customTheme}
      classNamePrefix="react-select Select"
    />
  );
};

export default Select;
