import React from "react";
import NavBar from "./NavBar";
import { FormattedMessage } from "react-intl";
import OverflowDropdown from "./OverflowDropdown";
import { SiteSettings } from "common/utils/holvikaari";

export type SignedOutNavBarProps = {
  rootPath: string;
  logoPath: string;
  locale: string;
  changeLocalePath: string;
  localizedLocales: Record<string, string>;
  signOutPath: string;
};

const SkipNav = () => (
  <a id="skip-nav" className="screenreader-text" href="#content">
    <FormattedMessage id="navbar.skip_navigation" />
  </a>
);

export default function SignedOutNavBar(props: SignedOutNavBarProps) {
  const availableLocales = SiteSettings.user_available_locales
    .filter((locale: string) => locale !== props.locale)
    .map((locale: string, i: number) => (
      <li key={i}>
        <a
          lang={locale}
          href={props.changeLocalePath.replace(/^\/en\//, `/${locale}/`)}
        >
          {props.localizedLocales[locale]}
        </a>
      </li>
    ));

  // Showing the nav only if it has any content
  const showRightNav =
    availableLocales.length > 0 ||
    SiteSettings.mfa_enabled ||
    SiteSettings.client_website_url;

  const getHumanizedUrl = (url: string): string => {
    const host = new URL(url).host;
    return host.substr(0, 1).toUpperCase() + host.substr(1);
  };

  return (
    <>
      <SkipNav />
      <NavBar
        logoPath={props.logoPath}
        rootPath={props.rootPath}
        rightNav={
          showRightNav ? (
            <OverflowDropdown
              moreId={"navbar.more"}
              elements={availableLocales.concat(
                [
                  SiteSettings.mfa_enabled && (
                    <li>
                      <a href={props.signOutPath}>
                        <i className="fa fa-sign-out-alt" />{" "}
                        <FormattedMessage id="navbar.sign_out" />
                      </a>
                    </li>
                  ),
                  SiteSettings.client_website_url &&
                    typeof SiteSettings.client_website_url === "string" &&
                    ((
                      <li className="semi-strong">
                        <a href={SiteSettings.client_website_url}>
                          <i className="fa fa-globe" />{" "}
                          {getHumanizedUrl(SiteSettings.client_website_url)}
                        </a>
                      </li>
                    ) as any),
                ].filter(element => !!element),
              )}
            />
          ) : undefined
        }
      />
    </>
  );
}
